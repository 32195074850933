import useMarketplaceChain from 'hooks/useMarketplaceChain'
import { Box, Flex, Text } from '../primitives'
type TabValue = 'collections' | 'mints'
import { paths } from '@reservoir0x/reservoir-sdk'
import { ChainContext } from 'context/ChainContextProvider'
import { GetServerSideProps, InferGetServerSidePropsType, NextPage } from 'next'
import {
  ComponentPropsWithoutRef,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useRouter } from 'next/router'
import supportedChains from 'utils/chains'
import {
  useTrendingCollections,
  useTrendingMints,
} from '@reservoir0x/reservoir-kit-ui'
import { CollectionsSortingOption } from 'components/common/CollectionsTimeDropdown'
import { MintsSortingOption } from 'components/common/MintsPeriodDropdown'
import { CollectionRankingsTable } from 'components/rankings/CollectionRankingsTable'
import { useTheme } from 'next-themes'
import { useMediaQuery } from 'react-responsive'
import fetcher from 'utils/fetcher'
import bg from '../../public/index/bgstarslarge.png'
import Image from 'next/image'
import Link from 'next/link'
import cat_1 from '../../public/index/teamgreen.png'
import cat_2 from '../../public/index/teamblue.png'
import cat_3 from '../../public/index/teamred.png'
import zir9t from '../../public/index/5497h-zir9t.png'
import sdfasd from '../../public/index/sdfasd.png'
// @ts-ignore
import intro_video from "../../public/videos/intro_video.mp4"

type Props = InferGetServerSidePropsType<typeof getServerSideProps>
const NFTBanner: NextPage = () => {
  const router = useRouter()
  const { routePrefix } = useMarketplaceChain()
  // not sure if there is a better way to fix this
  const { theme: nextTheme } = useTheme()
  const [theme, setTheme] = useState<string | null>(null)
  useEffect(() => {
    if (nextTheme) {
      setTheme(nextTheme)
    }
  }, [nextTheme])

  const isSSR = typeof window === 'undefined'
  const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

  const [tab, setTab] = useState<TabValue>('collections')
  const [sortByTime, setSortByTime] = useState<CollectionsSortingOption>('24h')

  const [sortByPeriod, setSortByPeriod] = useState<MintsSortingOption>('24h')
  let [countCard, setCountCard] = useState(0)
  let mintsQuery: Parameters<typeof useTrendingMints>['0'] = {
    limit: 20,
    period: sortByPeriod,
    type: 'any',
  }

  const { chain, switchCurrentChain } = useContext(ChainContext)

  useEffect(() => {
    if (router.query.chain) {
      let chainIndex: number | undefined
      for (let i = 0; i < supportedChains.length; i++) {
        if (supportedChains[i].routePrefix == router.query.chain) {
          chainIndex = supportedChains[i].id
        }
      }
      if (chainIndex !== -1 && chainIndex) {
        switchCurrentChain(chainIndex)
      }
    }
  }, [router.query])

  const {
    data: trendingCollections,
    isValidating: isTrendingCollectionsValidating,
  } = useTrendingCollections(
    {
      limit: 20,
      sortBy: 'volume',
      period: sortByTime,
    },
    chain.id,
    {
      fallbackData: '',
      keepPreviousData: true,
    }
  )
  const {
    data: featuredCollections,
    isValidating: isFeaturedCollectionsValidating,
  } = useTrendingCollections(
    {
      limit: 20,
      sortBy: 'sales',
      period: '24h',
    },
    chain.id,
    {
      fallbackData: '',
      keepPreviousData: true,
    }
  )
  const { data: trendingMints, isValidating: isTrendingMintsValidating } =
    useTrendingMints({ ...mintsQuery }, chain.id, {
      fallbackData: '',
      keepPreviousData: true,
    })
  let volumeKey: ComponentPropsWithoutRef<
    typeof CollectionRankingsTable
  >['volumeKey'] = '1day'

  switch (sortByTime) {
    case '30d':
      volumeKey = '30day'
      break
    case '7d':
      volumeKey = '7day'
      break
    case '24h':
      volumeKey = '1day'
      break
  }

  return (
    <div className="content">
      <div className="intro">
        <div className="intro__bg">
          <video
            width={500}
            muted={true}
            loop={true}
            playsInline={true}
            autoPlay={true}
          >
            <source src={intro_video} type="video/mp4" />
          </video>
        </div>
        <div className="intro__container">
          <div className='intro__wrap'>
            <div className='intro__main'>
              <h2 className='intro__title'>AVATAR COLLECTIBLE PLATFORM</h2>
              <div></div>
            </div>
            <div className='intro__bottom'>
               <div className='intro__controls'>
                <div className='intro__button'>
                <Link href={`/${routePrefix}/collections/trending`} style={{
                   padding: "22px 23px",
                   fontSize: "16px",
                   fontFamily: "Druk Wide Web",
                   color: "#000000",
                   backgroundColor: "#6966FF",
                   borderRadius:"20px",
                   cursor: "pointer",
                   outline: "none",
                   fontWeight:"700",
               }}>
                   <span style={{
                       color: "#ffffff",
                       fontSize: '16px',
                   }}>EXPLORE</span>
               </Link>
                </div>
                <div className='intro__button'></div>
                <div className='intro__button'>
                <Link href={`/${routePrefix}/explore`} style={{
                   padding: "22px 23px",
                   fontSize: "1em",
                   fontFamily: "Druk Wide Web",
                   color: "#ffffff",
                   backgroundColor: "transparent",
                   border:"1px solid #6966FF",
                   borderRadius:"20px",
                   cursor: "pointer",
                   outline: "none",
                   fontWeight:"700"
               }}>
                   <span style={{
                       color: "#ffffff",
                       fontSize: '16px',
                   }}>COLLECTIONS</span>
               </Link>
                </div>
               </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mission'>
        <div className='mission__container'>
          <div className='mission__wrap'>
            <h2 className='mission__title'>mission</h2>
            <div className='mission__box'></div>
            <div className="mission__text">
              <span>Our mission is to </span> 
              let people know the fun of doing sports
              <span> and build a virtual </span> 
              city which all involved in.
              </div>
          </div>
        </div>
      </div>
      {/* <div
        style={{
          width: '100%',
          height: '540px',
          background: `url(${zir9t.src}) center`,
          backgroundColor: '#000',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      ></div> */}
      {/* <div  style={{ height: `calc( 100vh - 440px )`, backgroundColor:"#2b303d",backgroundImage:"radial-gradient(hsla(0,0%,100%,.1) 1px,transparent 0)", backgroundSize:'40px 40px', backgroundPosition:"-19px -19px" }}>
         <Flex align="center" className='btnSty'  css={{
               flexDirection:"column",
               "@md":{
                   width:"40%",
                   height:"100%",
                   flexDirection:"row",
                   margin:"0 auto",
                  //  justifyContent:"space-around",
                  //  alignItems:"center"
               }
           }}>
               <Link href={`/${routePrefix}/collections/trending`} style={{
                   padding: "10px 20px",
                   margin: "40px",
                   fontSize: "1em",
                   fontFamily: "PX",
                   color: "#000000",
                   backgroundColor: "#ffffff",
                   borderRadius:"0.375rem",
                   cursor: "pointer",
                   outline: "none",
                   fontWeight:"600",
               }}>
                   <span style={{
                       color: "#000000",
                       fontSize: '20px',
                   }}>EXPLORE</span>
               </Link>
               <Link href={`/${routePrefix}/explore`} style={{
                   padding: "10px 20px",
                   marginRight: "10px",
                   fontSize: "1em",
                   fontFamily: "PX",
                   color: "#000000",
                   backgroundColor: "#ffffff",
                   borderRadius:"0.375rem",
                   cursor: "pointer",
                   outline: "none",
                   fontWeight:"600"
               }}>
                   <span style={{
                       color: "#000000",
                       fontSize: '20px',
                   }}>COLLECTIONS</span>
               </Link>
           </Flex>
      </div> */}
      {/* <div className='titleImg' >
        <div className='textSty' style={{
            position:"absolute",
            left:"100%",
            padding:"0.75rem",
            fontSize:'0.875rem',
            backgroundColor:"#ffffff",
            borderRadius:"0.25rem",
            color:"#000000",
        }}>Hey！Connect your wallet to begin your magic NFT trip.</div>
        <Image src={sdfasd} alt='xx'></Image>
      </div> */}
    </div>
  )
}

type TrendingCollectionsSchema =
  paths['/collections/trending/v1']['get']['responses']['200']['schema']
type TrendingMintsSchema =
  paths['/collections/trending-mints/v1']['get']['responses']['200']['schema']

export const getServerSideProps: GetServerSideProps<{
  ssr: {
    trendingMints: TrendingMintsSchema
    trendingCollections: TrendingCollectionsSchema
    featuredCollections: TrendingCollectionsSchema
  }
}> = async ({ params, res }) => {
  const chainPrefix = params?.chain || ''
  const reservoirBaseUrl = process.env.NEXT_PUBLIC_HOST_URL
  // init BaseApi
  // supportedChains.find((chain) => chain.routePrefix === chainPrefix) ||
  // DefaultChain
  // console.log("请求的路劲",reservoirBaseUrl)
  const headers: RequestInit = {
    headers: {
      'x-api-key': process.env.RESERVOIR_API_KEY || '',
    },
  }
  let trendingCollectionsQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
      period: '24h',
      limit: 20,
      sortBy: 'volume',
    }

  const trendingCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    trendingCollectionsQuery,
    headers
  )
  let featuredCollectionQuery: paths['/collections/trending/v1']['get']['parameters']['query'] =
    {
      period: '24h',
      limit: 20,
      sortBy: 'sales',
    }

  const featuredCollectionsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending/v1`,
    featuredCollectionQuery,
    headers
  )

  let trendingMintsQuery: paths['/collections/trending-mints/v1']['get']['parameters']['query'] =
    {
      period: '24h',
      limit: 20,
      type: 'any',
    }

  const trendingMintsPromise = fetcher(
    `${reservoirBaseUrl}/collections/trending-mints/v1`,
    trendingMintsQuery,
    headers
  )

  const promises = await Promise.allSettled([
    trendingCollectionsPromise,
    featuredCollectionsPromise,
    trendingMintsPromise,
  ]).catch((e) => {
    console.error(e)
  })

  const trendingCollections: Props['ssr']['trendingCollections'] =
    promises?.[0].status === 'fulfilled' && promises[0].value.data
      ? (promises[0].value.data as Props['ssr']['trendingCollections'])
      : {}
  const featuredCollections: Props['ssr']['featuredCollections'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
      ? (promises[1].value.data as Props['ssr']['featuredCollections'])
      : {}

  const trendingMints: Props['ssr']['trendingMints'] =
    promises?.[1].status === 'fulfilled' && promises[1].value.data
      ? (promises[1].value.data as Props['ssr']['trendingMints'])
      : {}

  res.setHeader(
    'Cache-Control',
    'public, s-maxage=120, stale-while-revalidate=180'
  )
  return {
    props: { ssr: { trendingCollections, trendingMints, featuredCollections } },
  }
}
export default NFTBanner
