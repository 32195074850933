import { FC } from 'react'
import { Text, Flex, Anchor } from '../primitives'
import {useRouter} from 'next/router'

type SectionTitleProps = {
    title: string
}

const SectionTitle: FC<SectionTitleProps> = ({ title }) => (
    <Text style="subtitle1" css={{ color: '$gray12', mb: 8 }}>
        {title}
    </Text>
)

type SectionLinkProps = {
    name: string
    href: string
}

const SectionLink: FC<SectionLinkProps> = ({ name, href }) => (
    <Anchor
        target="_blank"
        rel="noopener noreferrer"
        href={href}
        weight="medium"
        css={{ fontSize: 14, mt: 16 }}
    >
        {name}
    </Anchor>
)

const developerSectionLinks = [
    {
        name: 'Docs',
        href: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
    {
        name: 'API Reference',
        href: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
    {
        name: 'Github',
        href:`${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
]

const companySectionLinks = [
    {
        name: 'Jobs',
        href: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
    {
        name: 'Terms of Use',
        href: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
    {
        name: 'Privacy Policy',
        href: `${process.env.NEXT_PUBLIC_HOST_URL}`,
    },
]

export const Footer = () => {
    let router = useRouter()
    return (
        <Flex
            justify="center"
            css={{
                borderTop: '1px solid $gray7',
                borderStyle: 'solid',
                p: '$5',
                '@lg': {
                    p: '$6',
                },
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: 36,
                '@bp600': {
                    flexDirection: 'row',
                    gap: 0,
                },
            }}
        >
            {/* <Flex css={{ gap: 80, '@bp600': { gap: 136 } }}>
        <Flex direction="column">
          <SectionTitle title="Developers" />
          {developerSectionLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
        <Flex direction="column">
          <SectionTitle title="Company" />
          {companySectionLinks.map((props) => (
            <SectionLink key={props.name} {...props} />
          ))}
        </Flex>
      </Flex>
      <Flex
        direction="column"
        css={{ alignItems: 'flex-start', '@bp600': { alignItems: 'flex-end' } }}
      >
        <SectionTitle title="Join Reservoir Community" />
        <Flex css={{ gap: '$4', mt: 16 }}>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/xxxxxxxxxx"
          >
            <Button size="xs" color="gray3">
              <FontAwesomeIcon icon={faTwitter} width={14} height={14} />
            </Button>
          </a>
        </Flex>
      </Flex> */}
            <Flex style={{textAlign:"center"}}>
                {process.env.NEXT_PUBLIC_RIGHTS}
            </Flex>
        </Flex>
    )
}
